/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import ProductZoom from "../../Components/ProductZoom";
import Rating from "@mui/material/Rating";
import QuantityBox from "../../Components/QuantityBox";
import Button from "@mui/material/Button";
import { BsCartFill } from "react-icons/bs";
import { useContext, useEffect, useState } from "react";
import { FaRegHeart } from "react-icons/fa";
import { MdOutlineCompareArrows } from "react-icons/md";
import Tooltip from "@mui/material/Tooltip";
import RelatedProducts from "./RelatedProducts";

import { useParams } from "react-router-dom";
import { fetchDataFromApi, postData } from "../../utils/api";
import CircularProgress from "@mui/material/CircularProgress";
import { MyContext } from "../../App";
import { FaHeart } from "react-icons/fa";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";


const ProductDetails = () => {
  const [activeSize, setActiveSize] = useState(null);
  const [activeTabs, setActiveTabs] = useState(0);
  const [productData, setProductData] = useState([]);
  const [relatedProductData, setRelatedProductData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reviewsData, setreviewsData] = useState([]);
  const [isAddedToMyList, setSsAddedToMyList] = useState(false);

  let [cartFields] = useState({});
  let [productQuantity, setProductQuantity] = useState();
  const [tabError, setTabError] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isCustomTailoredSelected, setIsCustomTailoredSelected] = useState(false);
  const [customMeasurements, setCustomMeasurements] = useState({
    length: "",
    shoulder: "",
    chest: "",
    stomach: "",
    sleeves: "",
    hip: "",
    trouserLength: "",
    waist: "",
    trouserHip: "",
    thigh: "",
    knee: "",
    hem: "",
  }); // State for custom measurements

  const { id } = useParams();

  const context = useContext(MyContext);

  const isActive = (index) => {
    setActiveSize(index);
    setTabError(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setActiveSize(null);
    fetchDataFromApi(`/api/products/${id}`).then((res) => {
      setProductData(res);

      if (
        res?.productRam.length === 0 &&
        res?.productWeight.length === 0 &&
        res?.size.length === 0
      ) {
        setActiveSize(1);
      }

      fetchDataFromApi(
        `/api/products/subCatId?subCatId=${res?.subCatId
        }&location=${localStorage.getItem("location")}`
      ).then((res) => {
        const filteredData = res?.products?.filter((item) => item.id !== id);
        setRelatedProductData(filteredData);
      });
    }, []);

    fetchDataFromApi(`/api/productReviews?productId=${id}`).then((res) => {
      setreviewsData(res);
    });

    const user = JSON.parse(localStorage.getItem("user"));

    fetchDataFromApi(
      `/api/my-list?productId=${id}&userId=${user?.userId}`
    ).then((res) => {
      if (res.length !== 0) {
        setSsAddedToMyList(true);
      }
    });


    context.setEnableFilterTab(false);
  }, [id]);

  const [rating, setRating] = useState(1);
  const [reviews, setReviews] = useState({
    productId: "",
    customerName: "",
    customerId: "",
    review: "",
    customerRating: 1,
  });

  const onChangeInput = (e) => {
    setReviews(() => ({
      ...reviews,
      [e.target.name]: e.target.value,
    }));
  };

  const changeRating = (e) => {
    setRating(e.target.value);
    reviews.customerRating = e.target.value;
  };

  const addReview = (e) => {
    e.preventDefault();

    const user = JSON.parse(localStorage.getItem("user"));

    if (user !== null) {
      reviews.customerName = user?.name;
      reviews.customerId = user?.userId;
      reviews.productId = id;

      if (reviews.review !== "") {

        setIsLoading(true);

        postData("/api/productReviews/add", reviews).then((res) => {
          setIsLoading(false);

          reviews.customerRating = 1;

          setReviews({
            review: "",
            customerRating: 1,
          });

          fetchDataFromApi(`/api/productReviews?productId=${id}`).then((res) => {
            setreviewsData(res);
          });
        });
      } else {
        context.setAlertBox({
          open: true,
          error: true,
          msg: "Please add a Review",
        });
      }

    } else {
      context.setAlertBox({
        open: true,
        error: true,
        msg: "Please Login first",
      });
    }
  };

  const quantity = (val) => {
    setProductQuantity(val);
  };

  const addtoCart = () => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (!user) {
        context.setAlertBox({
            open: true,
            error: true,
            msg: "Please login to add items to the cart.",
        });
        return;
    }

    if (activeSize === null && !isCustomTailoredSelected) {
      context.setAlertBox({
        open: true,
        error: true,
        msg: "Please select a size or custom tailored option before adding to cart.",
      });
      return; // Stop the function from proceeding if no size or custom tailored option is selected
    }
    // Prepare cart fields
    const cartFields = {
        productTitle: productData?.name,
        image: productData?.images[0],
        rating: productData?.rating,
        price: productData?.price,
        quantity: productQuantity,
        subTotal: parseInt(productData?.price * productQuantity),
        productId: productData?.id,
        userId: user?.userId,
        countInStock: productData?.countInStock,
        size: isCustomTailoredSelected ? null : productData?.size[activeSize], // Send size or 'Custom Tailored'
        customMeasurements: isCustomTailoredSelected ? customMeasurements : null // Send custom measurements if selected
    };

    // console.log("Cart data: ",cartFields);
    context.addToCart(cartFields);
    context.setAlertBox({
        open: true,
        error: false,
        msg: "Product added to cart successfully!",
    });
};

  const selectedItem = () => { };

  const gotoReviews = () => {
    window.scrollTo({
      top: 550,
      behavior: "smooth",
    });

    setActiveTabs(2);
  };

  const addToMyList = (id) => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user !== undefined && user !== null && user !== "") {
      const data = {
        productTitle: productData?.name,
        image: productData?.images[0],
        rating: productData?.rating,
        price: productData?.price,
        productId: id,
        userId: user?.userId,
      };
      postData(`/api/my-list/add/`, data).then((res) => {
        if (res.status !== false) {
          context.setAlertBox({
            open: true,
            error: false,
            msg: "the product added in my list",
          });

          fetchDataFromApi(
            `/api/my-list?productId=${id}&userId=${user?.userId}`
          ).then((res) => {
            if (res.length !== 0) {
              setSsAddedToMyList(true);
            }
          });
        } else {
          context.setAlertBox({
            open: true,
            error: true,
            msg: res.msg,
          });
        }
      });
    } else {
      context.setAlertBox({
        open: true,
        error: true,
        msg: "Please Login to continue",
      });
    }
  };

  const handleCustomMeasurementChange = (e) => {
    setCustomMeasurements({
      ...customMeasurements,
      [e.target.name]: e.target.value,
    });
  };

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleDialogSubmit = () => {

    const updatedMeasurements = { ...customMeasurements };

    for (const key in updatedMeasurements) {
      if (updatedMeasurements[key] === "") {
        updatedMeasurements[key] = "N/A";
      }
    }
  
    // Update the state to ensure the cart has updated measurements
    setCustomMeasurements(updatedMeasurements);
    // Handle form submission logic
    // console.log("Custom Measurements: ", customMeasurements);
    setIsCustomTailoredSelected(true);
    handleDialogClose();
  };


  return (
    <>
      <section className="productDetails section">
        <div className="container">
          {productData?.length === 0 ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ minHeight: "300px" }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div className="row">
              <div className="col-md-4 pl-5 part1">
                <ProductZoom
                  images={productData?.images}
                  discount={productData?.discount}
                />
              </div>

              <div className="col-md-7 pl-5 pr-5 part2">
                <h2 className="hd text-capitalize">{productData?.name}</h2>
                <ul className="list list-inline d-flex align-items-center">
                  <li className="list-inline-item">
                    <div className="d-flex align-items-center">
                      <span className="text-light mr-2">Brands : </span>
                      <span>{productData?.brand}</span>
                    </div>
                  </li>

                  <li className="list-inline-item">
                    <div className="d-flex align-items-center">
                      <Rating
                        name="read-only"
                        value={parseInt(productData?.rating)}
                        precision={0.5}
                        readOnly
                        size="small"
                        sx={{color:"#202020"}}
                      />

                      <span
                        className="text-light cursor ml-2"
                        onClick={gotoReviews}
                      >
                        {reviewsData?.length} Review
                      </span>
                    </div>
                  </li>
                </ul>

                <div className="d-flex info mb-3">
                  <span className="oldPrice">₹: {productData?.oldPrice}</span>
                  <span className="netPrice price-color ml-2">
                    Rs: {productData?.price}
                  </span>
                </div>

                {productData?.countInStock >= 1 ? (
                  <span className="badge badge-success">IN STOCK</span>
                ) : (
                  <span className="badge badge-danger">OUT OF STOCK</span>
                )}

                <p className="mt-3"
                  style={{
                    whiteSpace: "pre-wrap", // Preserve spaces and line breaks
                    padding: "10px",
                  }}>{productData?.description?.slice(0, 230)}...</p>

                {productData?.size?.length !== 0 && (
                  <div className="productSize d-flex align-items-center">
                    <span>Size:</span>
                    <ul
                      className={`list list-inline mb-0 pl-4 ${tabError === true && "error"
                        }`}
                    >
                      {productData?.size?.map((item, index) => {
                        return (
                          <li className="list-inline-item">
                            <a
                              className={`tag ${activeSize === index ? "active" : ""
                                }`}
                              onClick={() => isActive(index)}
                            >
                              {item}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}

                <Button style={{ background: "#4C7383", color: "#fff", marginTop:"10px" }} onClick={handleDialogOpen}>Custom Tailored</Button>

                <Dialog open={isDialogOpen} onClose={handleDialogClose} fullWidth maxWidth="sm">
                  <DialogTitle>Custom Measurements</DialogTitle>
                  <hr />
                  <DialogTitle>Enter in Inches</DialogTitle>
                  <DialogContent>
                    <div className="row">
                      <div className="col-md-6">
                        <TextField
                          label="Length(लंबाई)"
                          variant="outlined"
                          fullWidth
                          margin="dense"
                          name="length"
                          type="number"
                          value={customMeasurements.length}
                          onChange={handleCustomMeasurementChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextField
                          label="Shoulder(कंधा)"
                          variant="outlined"
                          fullWidth
                          margin="dense"
                          name="shoulder"
                          type="number"
                          value={customMeasurements.shoulder}
                          onChange={handleCustomMeasurementChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextField
                          label="Chest(छाती)"
                          variant="outlined"
                          fullWidth
                          margin="dense"
                          name="chest"
                          type="number"
                          value={customMeasurements.chest}
                          onChange={handleCustomMeasurementChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextField
                          label="Stomach(पेट)"
                          variant="outlined"
                          fullWidth
                          margin="dense"
                          name="stomach"
                          type="number"
                          value={customMeasurements.stomach}
                          onChange={handleCustomMeasurementChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextField
                          label="Sleeves(आस्तीन)"
                          variant="outlined"
                          fullWidth
                          margin="dense"
                          name="sleeves"
                          type="number"
                          value={customMeasurements.sleeves}
                          onChange={handleCustomMeasurementChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextField
                          label="Hip"
                          variant="outlined"
                          fullWidth
                          margin="dense"
                          name="hip"
                          type="number"
                          value={customMeasurements.hip}
                          onChange={handleCustomMeasurementChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextField
                          label="Traousers length(पतलून की लंबाई)"
                          variant="outlined"
                          fullWidth
                          margin="dense"
                          name="trouserLength"
                          type="number"
                          value={customMeasurements.trouserLength}
                          onChange={handleCustomMeasurementChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextField
                          label="Waist(कमर)"
                          variant="outlined"
                          fullWidth
                          margin="dense"
                          name="waist"
                          type="number"
                          value={customMeasurements.waist}
                          onChange={handleCustomMeasurementChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextField
                          label="Trouser Hip"
                          variant="outlined"
                          fullWidth
                          margin="dense"
                          name="trouserHip"
                          type="number"
                          value={customMeasurements.trouserHip}
                          onChange={handleCustomMeasurementChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextField
                          label="Thigh(जाँघ)"
                          variant="outlined"
                          fullWidth
                          margin="dense"
                          name="thigh"
                          type="number"
                          value={customMeasurements.thigh}
                          onChange={handleCustomMeasurementChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextField
                          label="Knee(घुटना)"
                          variant="outlined"
                          fullWidth
                          margin="dense"
                          name="knee"
                          type="number"
                          value={customMeasurements.knee}
                          onChange={handleCustomMeasurementChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextField
                          label="Hem(मोहरी)"
                          variant="outlined"
                          fullWidth
                          margin="dense"
                          name="hem"
                          type="number"
                          value={customMeasurements.hem}
                          onChange={handleCustomMeasurementChange}
                        />
                      </div>
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={handleDialogSubmit} style={{ background: "#4C7383", color: "#fff" }}>
                      Submit
                    </Button>
                  </DialogActions>
                </Dialog>

                <div className="d-flex align-items-center mt-3 actions_">
                  <QuantityBox
                    quantity={quantity}
                    item={productData}
                    selectedItem={selectedItem}
                  />

                  <div className="d-flex align-items-center btnActions">
                    <Button
                      className="btn-blue btn-lg btn-big btn-round"
                      onClick={() => addtoCart()}
                    >
                      <BsCartFill /> &nbsp;
                      {context.addingInCart === true
                        ? "adding..."
                        : " Add to cart"}
                    </Button>

                    <Tooltip
                      title={`${isAddedToMyList === true
                        ? "Added to Wishlist"
                        : "Add to Wishlist"
                        }`}
                      placement="top"
                    >
                      <Button
                        className={`btn-blue btn-lg btn-big btn-circle ml-4`}
                        onClick={() => addToMyList(id)}
                      >
                        {isAddedToMyList === true ? (
                          <FaHeart className="text-danger" />
                        ) : (
                          <FaRegHeart />
                        )}
                      </Button>
                    </Tooltip>

                    <Tooltip title="Add to Compare" placement="top">
                      <Button className="btn-blue btn-lg btn-big btn-circle ml-2">
                        <MdOutlineCompareArrows />
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          )}

          <br />

          <div className="card mt-5 p-5 detailsPageTabs">
            <div className="customTabs">
              <ul className="list list-inline">
                <li className="list-inline-item">
                  <Button
                    className={`${activeTabs === 0 && "active"}`}
                    onClick={() => {
                      setActiveTabs(0);
                    }}
                  >
                    Description
                  </Button>
                </li>
                <li className="list-inline-item">
                  <Button
                    className={`${activeTabs === 2 && "active"}`}
                    onClick={() => {
                      setActiveTabs(2);
                    }}
                  >
                    Reviews ({reviewsData?.length})
                  </Button>
                </li>
              </ul>

              <br />

              {activeTabs === 0 && (
                <div className="tabContent"
                  style={{
                    whiteSpace: "pre-wrap", // Preserve spaces and line breaks
                    padding: "10px",
                  }}>{productData?.description}</div>
              )}
              {activeTabs === 2 && (
                <div className="tabContent">
                  <div className="row">
                    <div className="col-md-8">
                      <h3>Customer questions & answers</h3>
                      <br />

                      {reviewsData?.length !== 0 &&
                        reviewsData
                          ?.slice(0)
                          ?.reverse()
                          ?.map((item, index) => {
                            return (
                              <div
                                className="reviewBox mb-4 border-bottom"
                                key={index}
                              >
                                <div className="info">
                                  <div className="d-flex align-items-center w-100">
                                    <h5>{item?.customerName}</h5>

                                    <div className="ml-auto">
                                      <Rating
                                        name="half-rating-read"
                                        value={item?.customerRating}
                                        readOnly
                                        size="small"
                                        sx={{color:"#202020"}}
                                      />
                                    </div>
                                  </div>

                                  <h6 className="text-light">
                                    {item?.dateCreated?.split('T')[0]}
                                  </h6>

                                  <p>{item?.review} </p>
                                </div>
                              </div>
                            );
                          })}

                      <br className="res-hide" />

                      <form className="reviewForm" onSubmit={addReview}>
                        <h4>Add a review</h4>
                        <div className="form-group">
                          <textarea
                            className="form-control shadow"
                            placeholder="Write a Review"
                            name="review"
                            value={reviews.review}
                            onChange={onChangeInput}
                          ></textarea>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <Rating
                                name="rating"
                                value={rating}
                                precision={0.5}
                                onChange={changeRating}
                                sx={{color:"#202020"}}
                              />
                            </div>
                          </div>
                        </div>

                        <br />
                        <div className="form-group">
                          <Button
                            type="submit"
                            className="btn-blue btn-lg btn-big btn-round"
                          >
                            {isLoading === true ? (
                              <CircularProgress
                                color="inherit"
                                className="loader"
                              />
                            ) : (
                              "Submit Review"
                            )}
                          </Button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <br />

          {relatedProductData?.length !== 0 && (
            <RelatedProducts
              title="RELATED PRODUCTS"
              data={relatedProductData}
            />
          )}
        </div>
      </section>
    </>
  );
};

export default ProductDetails;
